<template>
    <LiefengContent>
        <template v-slot:title>组织体系</template>
        <template v-slot:toolsbarRight></template>
        <template v-slot:contentArea>
            <div>
                <div style="height: calc(100vh - 50px)">
                    <RelationGraph ref="seeksRelationGraph" :options="graphOptions" :on-node-click="onNodeClick" :on-line-click="onLineClick" />
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import RelationGraph from "relation-graph"
export default {
    components: {
        LiefengContent,
        RelationGraph,
    },
    data() {
        return {
            graphOptions: {
                allowSwitchLineShape: true,
                allowSwitchJunctionPoint: true,
                defaultJunctionPoint: "border",
                // 这里可以参考"Graph 图谱"中的参数进行设置:http://relation-graph.com/#/docs/graph
            },
        }
    },
    created() {
        this.showSeeksGraph()
    },
    methods: {
        showSeeksGraph() {
            var __graph_json_data = {
                rootId: "a",
                nodes: [
                    // node配置选项：http://relation-graph.com/#/docs/node
                    // node支持通过插槽slot完全自定义，示例：http://relation-graph.com/#/demo/adv-slot
                    { id: "a", text: "A", borderColor: "yellow" },
                    { id: "b", text: "B", color: "#43a2f1", fontColor: "yellow" },
                    { id: "c", text: "C", nodeShape: 1, width: 80, height: 60 },
                    { id: "e", text: "E", nodeShape: 0, width: 150, height: 150 },
                ],
                links: [
                    // link配置选项：http://relation-graph.com/#/docs/link
                    { from: "a", to: "b", text: "关系1", color: "#43a2f1" },
                    { from: "a", to: "c", text: "关系2" },
                    { from: "a", to: "e", text: "关系3" },
                    { from: "b", to: "e", color: "#67C23A" },
                ],
            }
            console.log(this.$refs.seeksRelationGraph);
            
            // this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, seeksRGGraph => {
            //     // Called when the relation-graph is completed
            // })
        },
        onNodeClick(nodeObject, $event) {
            console.log("onNodeClick:", nodeObject)
        },
        onLineClick(lineObject, $event) {
            console.log("onLineClick:", lineObject)
        },
    },
}
</script>

<style lang="less" scoped>
</style>